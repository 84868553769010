.themeCconfigurationFrame{
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #fff;
    margin-top: 0.5rem;
    padding: 1rem;
    flex: 1;
    overflow: hidden;
    .themeTopBox{
        margin-bottom: 0.5rem;
        .themeTitle{
            font-size: 1rem;
            color: #3D3D3D;
        }
        .ant-input-affix-wrapper{
            background: #F2F3F5;
            border: 0;
            width: 12rem;
            margin-left: 1rem;
            .ant-input{
                background: transparent;
            }
        }

    }
    .themeListBox {
        flex: 1;
        overflow: auto;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;
        // .scrollBox {
        //     height: 100%;
        //     overflow: auto;
        //     display: flex;
        //     flex-flow: row wrap;
        //     justify-content: flex-start;
        //     align-items: flex-start;
        //     align-content: flex-start;
        //     margin: 0 -0.5rem;

            .themeCard{
                width: 16rem;
                height: 9rem;
                border: 1px solid #E5E8EF;
                border-radius: 10px;
                cursor: pointer;
                margin: 0 0.5rem 1rem;
                padding: 0.8rem;
                .manageCardTitle{
                    font-size: 0.8rem;
                    font-weight: 500;
                }
                .manageCardTime{
                    font-size: 0.7rem;
                    color: #86909C;
                }

                .manageCardDescription {
                    font-size: 0.7rem;
                    color: #86909C;
                    height: 2rem;
                    margin-top: 0.5rem;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2; /* 设置为想要的行数 */
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                
                .manageCardButton{
                    text-align: right;
                    margin-top: 1rem;
                }


                .themeImage{
                    text-align: center;
                    margin-top: 30px;
                    
                    .anticon-plus{
                        font-size: 2rem;
                        color: #4E5969;
                    }
                    .plusTitle{
                        color: #86909C;
                        font-size: 0.7rem;
                        margin-top: 0.5rem;
                    }
                }
                
            }
        // }
    }
  }
  