.generalPolicyListFrame{
    flex: 1;
    overflow: hidden;
    .contentBox {
        height: 100%;
        display: flex;
        overflow: hidden;
        .leftBox{
            width: 300px;
            background: #fff;
            margin-right: 0.5rem;
            border-radius: 0.5rem;
            padding: 0.5rem;
            display: flex;
            flex-direction: column;
            .leftHeadBox {
                flex-shrink: 0;
                margin-bottom: 0.5rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 0.5rem;
                border-bottom: 0.05rem solid #e9e9e9;
        
                .headTitleText {
                  font-size: 0.8rem;
                  color: #333;
                  line-height: 1rem;
                }
        
                .classificationList{
                  .operateBtn {
                    line-height: 0;
                    cursor: pointer;
                    display: block;
        
                    .iconfont {
                      color: #b8b8b8;
                      font-size: 1rem;
                    }
        
                    &:hover {
                      .iconfont {
                        color: #1A9DF9;
                      }
                    }
                  }
                }
            }
            .policyTreeBox{
              flex: 1;
              overflow-y: auto;
              overflow-x: hidden;
            }
        }
        .centerBox{
            flex: 1;
            background: #fff;
            border-radius: 0.5rem;
            padding: 0.5rem;
            height: 100%;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            .policyListBox{
              flex: 1;
              overflow: hidden;
              .ant-spin-nested-loading{
                height: 100%;
                overflow: hidden;
                .ant-spin-container{
                  height: 100%;
                  overflow: hidden;
                }
              }
            }
        }
        .rightBox{
            background: #fff;
            width: 300px;
            border-radius: 0.5rem;
            padding: 0.5rem;
            height: 100%;
            display: flex;
            flex-direction: column;
            margin-left: 0.5rem;
            .firstTabs{
                color: #4E5969;
                font-size: 0.7rem;
                height: 1.6rem;
                line-height: 1.6rem;
                div{
                    float: left;
                    margin-right: 0.5rem;
                    border-radius: 0.8rem;
                    padding: 0 0.8rem;
                    cursor: pointer;
                }
                .select{
                    background: #F2F3F5;
                    color: #165DFF;
                }
            }
        }
    }
  }
  
.addThemeModal{
    .modalTitle{
        font-size: 1rem;
        .anticon-exclamation-circle{
          margin-right: 0.5rem;
        }
    }
}