.allNumViewFrame {
  width: 100%;
  height: 100%;

  .title{
    color: #333333;
    font-size: 0.8rem;
    padding: 1rem 0;
  }

  .dataShow{
    padding: 0.5rem 0;
    .firstNum {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 13.6rem;
      height: 100%;
      background: #fff;
      border-radius: 0.5rem;
      .numInfo {
        padding-left: 0.5rem;
        max-width: 100%;
        span {
          margin-left: 0.3rem;
          font-size: 1.5rem;
          color: #0099FF;
        }
      }
      .progressBox {
        display: flex;
        justify-content: space-around;
        .progressItem {
          width: 28%;
          text-align: center;
          .scoreValue {
            font-size: 1rem;
          }
          .ant-progress-inner{
            overflow: initial;
          }
        }
        .progressItem:first-child{
          .ant-progress-inner{
            .ant-progress-bg:first-child{
              box-shadow: 0 1px 4px 0 rgba(248,61,37,0.5)
            }
          }
        }
        .progressItem:nth-child(2){
          .ant-progress-inner{
            .ant-progress-bg:first-child{
              box-shadow: 0 1px 4px 0 #259FF8;
            }
          }
        }
        .progressItem:last-child{
          .ant-progress-inner{
            .ant-progress-bg:first-child{
              box-shadow: 0 1px 4px 0 #6CC42B;
            }
          }
        }
      }
    }
    .scoreTotalBox {
      background: #fff;
      border-radius: 0.5rem;
    }

    .numBox {
      cursor: pointer;
      box-sizing: border-box;
      padding: 1.2rem 0;
      width: 16%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-around;
      color: #868686;
      div {
        .messageNumber {
          font-size: 1.5rem;
          color: #000;
          margin-right: 0.3rem;
        }
      }
      .userPersonalDataType{
        display: flex;
        align-items: center;
      }

      &:hover {
        color: #1A9DF9;

        div {
          .messageNumber {
            color: #1A9DF9;
          }
        }
      }
    }
  }
}
