.policyAnnotationFrame{
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .annotateTeam{
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .teamTopBox{
            display: flex;
            .title{
                font-weight: bold;
                font-size: 0.7rem;
                color: #4E5969;
                flex: 1;
            }
        }
        .rightBoxList{
            flex: 1;
            overflow: auto;
        }
    }
    .annotateOptions{
        flex: 1;
        display: flex;
        flex-direction: column;
        .teamTopBox{
            margin-bottom: 0.5rem;
            .title{
                font-weight: bold;
                font-size: 0.7rem;
                color: #4E5969;
                flex: 1;
            }
        }
        .optionPreview{
            flex: 1;
            border-radius: 10px;
            background: #F7F8FA;
            margin-top: 1rem;
            padding:  0.5rem ;
            overflow: auto;
            .title{
                color: #4E5969;
                font-size: 0.7rem;
                font-weight: bold;
                text-align: center;
                line-height: 1.5rem;
            }
            .ListItem{
                color: #3D3D3D;
                font-size: 0.7rem;
                height: 1.8rem;
                line-height: 1.8rem;
                border-bottom: 1px solid #C2C2C2;
                padding: 0 0.6rem;
            }
        }
    }
}