.policyAnnotationDataFrame{
    height: 100%;
    display: flex;
    flex-direction: column;
    .topBox{
        background: #fff;
        height: 5rem;
        width: 100%;
        margin-bottom: 0.5rem;
        padding: 0.5rem;
        border-radius: 0.5rem;
        .titleBox{
            display: flex;
            .titleLeft{
                flex: 1;
                .title{
                    font-size: 1.2rem;
                    font-weight: 500;
                }
                .department{
                    font-size: 0.7rem;
                    color: #4E5969;
                }
                .sort{
                    font-size: 0.7rem;
                    color: #4E5969;
                }
            }
            .titleRight{
                .anticon-unordered-list,.backIcon{
                    color: #409EFF;
                    cursor: pointer;
                }
                .backIcon{
                    font-size: 1rem;
                    float: right;
                }
            }
        }
        .description{
            color: #1D2129;
            font-size: 0.7rem;
            margin-top: 0.5rem;
        }
    }
    .contentBox {
        flex: 1;
        display: flex;
        overflow: hidden;
        .leftBox{
            background: #fff;
            flex: 1;
            margin-right: 0.5rem;
            border-radius: 0.5rem;
            padding: 0.5rem;
            overflow: hidden;
            .ant-spin-nested-loading{
                height: 100%;
                overflow: hidden;
                .ant-spin-container{
                    height: 100%;
                    overflow: hidden;
                }
            }
        }
        .rightBox{
            background: #fff;
            width: 300px;
            border-radius: 0.5rem;
            padding: 0.5rem;
            height: 100%;
            display: flex;
            flex-direction: column;
            .firstTabs{
                color: #4E5969;
                font-size: 0.7rem;
                height: 1.6rem;
                line-height: 1.6rem;
                div{
                    float: left;
                    margin-right: 0.5rem;
                    border-radius: 0.8rem;
                    padding: 0 0.8rem;
                    cursor: pointer;
                }
                .select{
                    background: #F2F3F5;
                    color: #165DFF;
                }
            }
        }
    }
  }
  