.policyOperationsFrame{
    height: 100%;
    // background: #f0f2f5;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .topTitle{
        position: relative;
        height: 3rem;
        line-height: 3rem;
        font-size: 1rem;
        background: #fff;
        margin-bottom: 0.5rem;
        padding-left: 1rem;
    }
    .content{
        display: flex;
        flex: 1;
        overflow: hidden;
    }
}