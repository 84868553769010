.operationRecordFrame{
    position: relative;
    height: 100%;
    background: #fff;
    .title{
        padding: 0.5rem 1rem;
        font-size: 1.2rem;
        text-align: center;
        background: #f0f2f5;
    }
    .operationState{
        display: flex;
        background: white;
        padding-top: 0.5rem;
        // margin-bottom: 0.5rem; 
        border-bottom: 0.5rem solid #f0f2f5;
        .stateItem{
            text-align: center;
            flex: 1;
            border-bottom: 2px solid #ffffff;
            border-right: 1px solid #d9d9d9;
            font-size: 0.8rem;
            cursor: pointer;
            p{
                margin-bottom: 0.5rem;
            }
            .anticon{
                margin-right: 5px;
            }
            .anticon-check-circle{
                color: #52c41a;
            }
            .anticon-close-circle{
                color: #ff4d4f;
            }
        }
        .stateItem:last-child{
            border-right:0
        }
    }
    .oprationBox{
        // text-align: right;
        font-size: 0.7rem;
        padding: 1rem;
        background: #fff;
        // border-top: 1px solid #f0f0f0;
        display: flex;
        justify-content: space-between;
        .oprationLeft{
            .operationItem{
                height: 1.6rem;
                line-height: 1.6rem;
                width: 320px;
                display: inline-block;
                margin-bottom: 0.5rem;
                .operationtitle{
                    display: inline-block;
                    max-width: 70px;
                }
                .ant-picker,.ant-input,.ant-input-affix-wrapper{
                    width: 240px;
                }
                .ant-input,.ant-input-affix-wrapper{
                    flex: 1;
                }
            }
        //     float: left;
        // }
        // .oprationRight{
        //     float: right;
        }
    }
    .table{
        background: #fff;
        padding: 0 1rem 0 1rem;
    }
}