.knowledgeSystemOperateFrame {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  cursor: default;
  background-color: #f0f2f5;
  user-select: none;

  .headerBox {
    flex-shrink: 0;
    height: 3rem;
    background-color: #fff;
    border-radius: 0.4rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;

    .boxName {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .iconfont {
        font-weight: bold;
        color: #333;
        font-size: 1.3rem;
        margin-right: 0.5rem;
      }

      .systemNameBox {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .boxName {
          flex-shrink: 0;
          margin-right: 0.2rem;
          font-size: 0.75rem;
          color: #333;
        }

        .inputBox {
          flex: 1;
          box-shadow: 0 0.05rem 0 0 #d3d3d3;
          line-height: 1;
          padding: 0 0.5rem;

          input {
            width: 12rem;
            border: none;
            outline: none;
            box-shadow: none;
            font-size: 0.7rem;
            padding: 0;
            color: #1A9DF9;
          }
        }
      }
    }

    .operateBox {
      flex-shrink: 0;
      margin-left: 1rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .operateBtn {
        display: block;
        line-height: 1;
        padding: 0.3rem 0.8rem;
        font-size: 0.6rem;
        color: #fff;
        cursor: pointer;
        background-color: #c2c2c2;
        border-radius: 0.2rem;

        +.operateBtn {
          margin-left: 1rem;
        }

        &.saveBtn {
          background-color: #1A9DF9;
        }
      }
    }
  }

  .contentBox {
    flex: 1;
    display: flex;
    justify-content: space-between;
    overflow: hidden;

    > div {
      background-color: #fff;
      border-radius: 0.4rem;
      padding: 1rem;
    }

    .rightBox {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .topToolBox {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        box-shadow: 0 0.05rem 0 0 #f0f2f5;

        .operateBtn {
          display: flex;
          align-items: center;
          padding: 0.3rem 0.5rem;
          border-radius: 0.2rem;
          background-color: #1A9DF9;
          font-size: 0.7rem;
          color: #fff;
          cursor: pointer;

          .iconfont {
            font-size: 0.9rem;

            &.icon-tree-01 {
              transform: rotate(180deg);
            }
          }

          .btnName {
            display: block;
            margin-left: 0.5rem;
            line-height: 1;
          }
        }

        .operateSummaryBox {
          display: flex;
          align-items: center;

          .tipText {
            display: block;
            font-size: 0.8rem;
            color: #333;
            margin-right: 0.5rem;
            line-height: 1;
          }

          .operateSummaryResultBox {
            display: flex;
            align-items: center;

            .summaryBtn {
              display: flex;
              align-items: center;
              font-size: 0.8rem;
              line-height: 1;
              cursor: pointer;

              .iconfont {
                font-size: 0.9rem;
              }

              +.summaryBtn {
                margin-left: 0.8rem;
              }

              &.confirmBtn {
                color: #1A9DF9;
              }

              &.cancelBtn {
                color: #FF3300;
              }

              &.disabled {
                opacity: 0.6;
                cursor: not-allowed;
              }
            }
          }
        }
      }
      .ant-spin-nested-loading{
        height: 100%;
      }
      .systemDesignBox {
        flex: 1;
        background-color: #f0f2f5;
        overflow: hidden;
        position: relative;
        border-radius: 0.4rem;

        .ant-modal-root {
          .knowledgeSystemOperateModal, .ant-modal-mask {
            position: absolute;

            .ant-modal-header {
              .titleBox {
                margin: 0!important;
              }
            }

            .ant-modal-body {
              padding: 0;

              .modalContentBox {
                padding: 1rem;
              }
            }
          }

          .colorFillBox {
            padding: 0;
            background: none;
            width: 100%;

            > div {
              width: 100%!important;
            }
          }
        }

        .treeRootBox {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 100%;
          min-width: 100%;
          position: absolute;
          left: 0;
          top: 0;

          .treeNodeBox {
            display: flex;
            align-items: center;

            .treeNode {
              display: flex;
              align-items: center;
              border-radius: 0.2rem;
              //overflow: hidden;
              background: #fff;
              padding: 0.2rem 0.5rem;
              min-height: 1.6rem;
              user-select: none;
              position: relative;

              .checkedBox {
                display: block;
                width: 0.5rem;
                height: 0.5rem;
                border-radius: 0.1rem;
                background-color: #f0f2f5;
                flex-shrink: 0;
                margin-right: 0.3rem;
              }

              .inputBox {
                display: flex;
                align-items: center;
                height: 100%;
                overflow: hidden;

                .treeNodeNameInput {
                  display: block;
                  width: 5.5rem;
                  background: none;
                  border: none;
                  //box-shadow: 0 0.05rem 0 0 #c3c3c3;
                  text-align: center;
                  font-size: 0.6rem;
                  color: #333;
                  padding: 0;
                  user-select: none;
                  cursor: default;
                  vertical-align: middle;
                  // line-height: 1.85rem;
                  position: relative;
                }
              }

              .treeNodeName {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 5.5rem;
                text-align: center;
                font-size: 0.6rem;
                color: #333;
                position: relative;

                .iconfont {
                  flex-shrink: 0;
                  font-size: 0.8rem;
                  color: #1A9DF9;
                  cursor: default;
                }

                > span {
                  flex: 1;
                }
              }

              .colorFillBox {
                display: none;
                position: absolute;
                width: 12rem;
                left: 100%;
                top: 50%;
                transform: translateY(-50%);
                padding-left: 1rem;

                .colorFillContentBox {
                  background-color: #fff;
                  border-radius: 0.4rem;
                  padding: 0.5rem;
                  overflow: hidden;
                  box-shadow: 0 0.3rem 0.8rem 0.05rem rgba(0,0,0,0.25);

                  > div {
                    width: 100%!important;
                    font-family: unset !important;
                  }

                  .operateColorBox {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .operateBtn {
                      display: block;
                      width: 45%;
                      line-height: 1;
                      margin-top: 0.5rem;
                      padding: 0.3rem 0;
                      border-radius: 0.2rem;
                      background-color: #c9c4c4;
                      font-size: 0.6rem;
                      color: #333;
                      cursor: pointer;
                      text-align: center;
                    }

                    .confirmBtn {
                      background-color: #3DADEE;
                      color: #fff;
                    }
                  }
                }

                &:before {
                  content: '';
                  position: absolute;
                  left: 0;
                  top: 50%;
                  border-width: 0.5rem;
                  border-style: solid;
                  border-color: transparent;
                  border-right-color: #fff;
                  transform: translateY(-50%);
                  //box-shadow: 0 0.3rem 0.8rem 0.05rem rgba(0,0,0,0.25);
                }
              }

              .directAddNodeBox {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;

                .addNodeBtn {
                  position: absolute;
                  line-height: 0;
                  cursor: pointer;
                  background: #fff;
                  border-radius: 50%;

                  .iconfont {
                    font-size: 0.8rem;
                    color: #1A9DF9;
                  }

                  &.topBtn {
                    left: 50%;
                    top: -1.1rem;
                    transform: translateX(-50%);
                  }

                  &.bottomBtn {
                    top: calc(100% + 0.3rem);
                    left: 50%;
                    transform: translateX(-50%);
                  }

                  &.leftBtn {
                    top: 50%;
                    right: calc(100% + 0.3rem);
                    transform: translateY(-50%);
                  }

                  &.rightBtn {
                    top: 50%;
                    left: calc(100% + 0.3rem);
                    transform: translateY(-50%);
                  }
                }
              }

              .introBtn {
                position: absolute;
                z-index: 1000;
                left: calc(100% + 0.1rem);
                bottom: calc(100% - 0.2rem);
                line-height: 0;
                cursor: pointer;

                .iconfont {
                  font-size: 0.6rem;
                  color: #919191;
                }
              }

              &.selectedNode {
                box-shadow: 0 0 0 0.2rem #1A9DF9;
                z-index: 10;
              }
            }

            .treeChildNodeBox {
              display: flex;
              flex-direction: column;
              margin-left: 3rem;
            }
          }
        }

        .reductionPositionBtn {
          position: absolute;
          line-height: 1;
          top: 1rem;
          right: 1rem;
          cursor: pointer;

          .iconfont {
            font-size: 1.3rem;
            color: #b5b5b5;
          }
        }
      }
    }
  }

  .selfMenuBox {
    position: fixed;
    z-index: 1000;
    top: 15rem;
    right: 10rem;
    background-color: #fff;
    border-radius: 0.4rem;
    padding: 1rem 0.5rem;
    //width: 7rem;
    box-shadow: 0 0.3rem 0.8rem 0.05rem rgba(0,0,0,0.25);
    user-select: none;
    cursor: default;
    visibility: hidden;

    .scrollBox {
      height: 100%;
      max-height: 15rem;
      overflow: auto;

      .menuItem {
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #333;
        padding: 0.3rem 0.5rem;
        border-radius: 0.2rem;

        .iconfont {
          font-size: 0.8rem;
          flex-shrink: 0;
          margin-right: 0.3rem;

          &.icon-fujihua {
            font-size: 0.7rem;
          }
          &.icon-copy {
            font-size: 0.85rem;
          }
        }

        .menuItemName {
          font-size: 0.7rem;
          line-height: 1;
        }

        +.menuItem {
          margin-top: 0.2rem;
        }

        &:hover {
          background-color: #1A9DF9;
          color: #fff;
        }
      }
    }
  }
}

.ant-popconfirm {
  .ant-popover-content {
    .ant-popover-inner {
      border-radius: 0.4rem;

      .ant-popover-inner-content {

        .ant-popover-buttons {
          .ant-btn {
            font-size: 0.6rem;
            height: 1rem;
            border-radius: 0.2rem;
          }
        }
      }
    }
  }
}

// 引用体系
.systemItemBox{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  min-height: 3rem;

  .systemItem {
    display: flex;
    flex-direction: column;
    width: 11rem;
    height: 17.5rem;
    padding: 0.5rem;
    margin: 0.5rem;
    position: relative;
    background-color: #fff;
    box-shadow: 0 0.1rem 0.5rem 0.05rem rgba(109,141,159,0.47);
    border-radius: 0.4rem;
    overflow: hidden;
    cursor: pointer;
    .systemImg {
      flex: 1;
      overflow: hidden;
      border-radius: 0.2rem;
      background-color: #f0f0f0;

      > img {
        display: block;
        width: 100%;
        margin-top: 50%;
      }
    }
    .systemName {
      flex-shrink: 0;
      height: 1.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      margin-top: 0.4rem;

      > span {
        text-align: justify;
        font-size: 0.6rem;
        color: #333;
        line-height: 0.8rem;
        display: -webkit-box; /* 使用flex布局 */
        -webkit-box-orient: vertical; /* 纵向排列 */
        -webkit-line-clamp: 2; /* 最多展示两行文字 */
        overflow: hidden; /* 隐藏超出部分 */
      }
    }
  }
}

// 笔记详情
.modalContentBox {
  .noteOpration{
    display: flex;
    margin-bottom: 0.5rem;
    .timeSort{
      position: relative;
      flex: 1;
      // cursor: pointer;
      .anticon-caret-up{
          position: absolute;
          top: 2px;
          left: 60px;
          margin-right: 0;
          font-size: 0.6rem;
      }
      .anticon-caret-down{
          position: absolute;
          top: 9px;
          left: 60px;
          margin-right: 0;
          font-size: 0.6rem;
      }
    }
    .deleteSortBtn{
      cursor: pointer;
    }
    .addSystemBtn{
      cursor: pointer;
    }
  }
  .noteListItem{
    background: #F0F5F8;
    border-radius: 8px;
    padding: 0.5rem;
    margin-bottom: 1rem;
    .typeAdd{
      color: red;
    }
    .noteDetails{
      margin-bottom: 0.5rem;
      color: #333333;
      font-size: 0.7rem;
    }
    .noteOriginal{
      // text-indent: 2em;
      background: #fff;
      padding: 0.5rem;
      color: #666666;
      font-size: 0.7rem;
    }
    .source{
      background: #fff;
      text-align: right;
      color: #666666;
      span{
        color: #1A9DF9;
        padding-left: 0.5rem;
        cursor: pointer;
      }
    }
  }
}
.writeExplainBox{
  .lableTitle{
    margin-bottom: 10px;
  }
}

.knowledgeSystemOperateModal {
  .ant-modal {
    .ant-modal-content {
      .titleBox {
        margin: 0;
      }
    }

    .ant-modal-body {
      max-height: 60vh;
      overflow: auto;
    }
  }
}

// 设置节点属性
.setAttributeBox {
  user-select: none;
  .attrItemBox {
    display: flex;
    align-items: flex-start;

    .labelText {
      flex-shrink: 0;
      width: 3.5rem;
      text-align-last: justify;
      font-size: 0.7rem;
      color: #333;
      line-height: 1.6rem;
    }

    .attrContent {
      flex: 1;
      overflow: hidden;

      .ant-input {
        box-shadow: none;
        border-color: #d5d5d5!important;
        border-radius: 0.4rem;
      }

      .ant-input-textarea {
        textarea {
          resize: none;
          box-shadow: none;
          border-color: #d5d5d5!important;
          border-radius: 0.4rem;
        }
      }

      .nodeTypeListBox {
        display: flex;
        align-items: center;
        height: 1.6rem;
        padding-left: 0.3rem;

        .radioItem {
          display: flex;
          align-items: center;
          cursor: pointer;

          .checkedBox {
            display: block;
            width: 0.6rem;
            height: 0.6rem;
            border-radius: 50%;
            box-shadow: 0 0 0 0.05rem #d5d5d5;
            flex-shrink: 0;
            margin-right: 0.3rem;
            position: relative;

            &:before {
              content: "";
              position: absolute;
              width: 0.3rem;
              height: 0.3rem;
              border-radius: 50%;
              left: 0.15rem;
              top: 0.15rem;
              background: #FF3D24;
              display: none;
            }
          }

          .typeText {
            font-size: 0.6rem;
            color: #333;
            line-height: 1rem;
          }

          +.radioItem {
            margin-left: 1.5rem;
          }

          &.activeStatus {
            .checkedBox {
              box-shadow: 0 0.05rem 0.3rem 0.05rem rgba(255,61,36,0.8);
              &:before {
                display: block;
              }
            }

            .typeText {
              color: #FF3D24;
            }
          }
        }
      }

      
    }

    +.attrItemBox {
      margin-top: 1rem;
    }
  }
}




// 添加兄弟节点弹窗
.addNodeListBox {
  display: flex;
  user-select: none;
  overflow: hidden;

  > div {
    width: 50%;
    height: 100%;
    flex-shrink: 0;

    .titleText {
      display: block;
      font-size: 0.7rem;
      line-height: 1rem;
      color: #333;
      margin-bottom: 0.5rem;
      flex-shrink: 0;
    }
  }

  .addAreaBox {
    display: flex;
    flex-direction: column;
    padding-right: 1rem;

    .customBox {
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      box-shadow: 0 0.05rem 0 0 #e0e0e0;

      .inputBox {
        display: flex;
        align-items: center;


        .ant-input {
          box-shadow: none;
          border-color: #d5d5d5!important;
          border-radius: 0.4rem;
          flex: 1;
        }

        .addNodeBtn {
          display: block;
          flex-shrink: 0;
          margin-left: 0.5rem;
          font-size: 0.6rem;
          color: #fff;
          cursor: pointer;
          background: #1A9DF9;
          border-radius: 0.2rem;
          height: 1.2rem;
          line-height: 1.2rem;
          padding: 0 0.8rem;
        }
      }
    }

    .systemBox {
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      .systemHeadBox {
        flex-shrink: 0;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.5rem;

        .titleText {
          margin-bottom: 0;
        }

        .ant-input {
          box-shadow: none;
          border: none;
          border-bottom: 0.05rem solid #e0e0e0;
          background: none;
          height: 1rem;
          font-size: 0.7rem;
          width: 6rem;
          text-align: center;
        }
      }

      .labelListBox {
        height: 100%;
        margin-top: 0;
        padding: 0.25rem;
        background-color: #e6e6e696;
        border-radius: 0.4rem;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 3rem;
      
        .scrollBox {
          width: 100%;
          height: 100%;
          overflow: auto;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: center;
          align-content: flex-start;
          margin-right: -0.15rem;
          padding-right: 0.15rem;
      
          .labelItem {
            display: block;
            margin: 0.25rem;
            padding: 0.2rem 0.5rem;
            background-color: #fff;
            border-radius: 0.2rem;
            font-size: 0.6rem;
            color: #333;
            cursor: pointer;
      
            &:hover {
              color: #fff;
              background-color: #1A9DF9;
            }
      
            &.selectedStatus {
              background-color: #ccc;
              cursor: not-allowed;
              opacity: 0.5;
      
              &:hover {
                color: #333;
                background-color: #ccc;
              }
            }
      
            &.activeStatus {
              background-color: #1A9DF9;
              color: #fff;
            }
          }
      
          .noneDataBox {
            width: 100%;
            height: 100%;
            flex-direction: column;
      
            .iconfont {
              margin: 0;
              font-size: 2rem;
            }
      
            > span {
              font-size: 0.7rem;
              margin-top: 0.5rem;
              color: #a4a0a0;
            }
          }
        }
      }
    }
  }

  .selectedNodeListBox {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    box-shadow: -0.05rem 0 0 0 #e0e0e0;

    .nodeListBox {
      display: block;
      overflow: auto;
      flex: 1;
      margin-right: -0.2rem;
      padding-right: 0.2rem;

      .nodeItem {
        display: flex;
        width: 100%;
        align-items: center;
        overflow: hidden;
        height: 1.2rem;
        background: #e6e6e696;
        padding: 0 0.5rem;
        border-radius: 0.2rem;

        .nodeName {
          font-size: 0.6rem;
          line-height: 1rem;
          color: #333;
          cursor: default;
          flex: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .operateBox {
          margin-left: 0.5rem;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .operateBtn {
            line-height: 0;
            cursor: pointer;

            .iconfont {
              font-size: 0.75rem;
              color: #999;

              &.icon-B {
                font-size: 0.9rem;
              }

              &.icon-xiangxia {
                font-size: 0.9rem;
              }

              &.icon-jujue {
                color: #e1440a;
              }
            }

            +.operateBtn {
              margin-left: 0.5rem;
            }
          }
        }

        +.nodeItem {
          margin-top: 0.5rem;
        }
      }

      .noneDataBox {
        width: 100%;
        height: 100%;
        flex-direction: column;
        background-color: #e6e6e696;
        border-radius: 0.4rem;

        .iconfont {
          margin: 0;
          font-size: 2rem;
        }

        > span {
          font-size: 0.7rem;
          margin-top: 0.5rem;
          color: #a4a0a0;
        }
      }
    }
  }
}

// 添加兄弟节点提示
.addBtnTip {
  .ant-tooltip-content {
    zoom: 0.6;
    margin-left: -1rem;
    position: relative;
  }
}

// 节点描述文字
.introTooltipBox {
  .ant-tooltip-content {
    font-size: 0.6rem;
    margin-left: -0.9rem;
    position: relative;
    bottom: -0.4rem;

    .ant-tooltip-inner {
      max-width: 10rem;
      white-space: pre-wrap;
      word-break: break-all;
      text-align: justify;
    }
  }
}
