.generalPolicyFrame{
  height: 100%;
 

  .policyThemeListBox{
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    .topTabs{
      position: relative;
      height: 2.5rem;
      line-height: 2.5rem;
      font-size: 1rem;
      background: #fff;
      margin-bottom: 0.5rem;
      padding-left: 1rem;
      .teamManagement{
        position: absolute;
        right: 80px;
        left: 160px;
        top: 0;
        display: flex;
        height: 50px;
        .teamManagementList{
          flex: 1;
          overflow-x: auto;
          overflow-y: hidden;
          text-align: right;
        }
      }
      .ant-tabs-top > .ant-tabs-nav::before{
        border-bottom: 0;
      }
    }
    .policyThemeList{
      flex: 1;
      overflow: hidden;
      .ant-spin-nested-loading,.ant-spin-container{
        height: 100%;
      }
    }
    
  }
  
}

.addThemeModal{
  .modalTitle{
    font-size: 1rem;
    .anticon-exclamation-circle{
      margin-right: 0.5rem;
    }
  }
  .ant-input-number-group-addon{
    background: none;
    border: 0;
    
  }
  .ant-input-number-group-addon:first-of-type {
    padding: 0 0.5rem 0 0;
  }
  .ant-input-number-group-addon:last-of-type {
    padding: 0 0 0 0.5rem;
  }
}
