.policyDataSourceFrame{
    .timeRage{
        .timeTopBox{
            height: 30px;
            line-height: 30px;
            margin-bottom: 0.5rem;
            .title{
                font-size: 0.7rem;
                font-weight: bold;
                color: #4E5969;
            }
            .switchBox{
                font-size: 0.7rem;
                float: right;
            }
        }
    }


    .filterWords,.blockWords,.dataSource{
        .filterWordsTitle{
            font-size: 0.8rem;
            font-weight: bold;
            color: #1D2129;
        }
        .switchBox{
            margin-top: 0.5rem;
            font-size: 0.7rem;
            color: #4E5969;
        }
        .tableTitle{
            display: flex;
            margin: 0.5rem 0;
            height: 27px;
            line-height: 27px;
            .inputSearchBox{
                border: 1px solid #d9d9d9;
                border-radius: 0.5rem;
                padding: 0 6px;
                .ant-input-affix-wrapper{
                    border: 0;
                    width: 110px;
                    box-shadow: none;
                    height: 26px;
                    float: left;
                }
            }
            .title{
                font-size: 0.7rem;
                color: #4E5969;
                flex: 1;
            }
            .anticon {
                cursor: pointer;
            }
            .uploadWordsBox{
                position: relative;
                cursor: pointer;
                .uploadWords{
                    opacity: 0;
                    position: absolute;
                    left: 0;
                    width: 116px;
                    cursor: pointer;
                }
            }
            
        }
        .selectStatusBox{
            font-size: 0.6rem;
        }
    }
    .dataSource{
        .tableTitle{
            justify-content: end;
        }
    }
}

.addWordsModal{
    .modalTitle{
        font-size: 1rem;
        .anticon-exclamation-circle{
          margin-right: 0.5rem;
        }
    }
}