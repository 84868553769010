.TagFrame{
    background: white;
    position: relative;
    // 这个页面的列表可以复制文字
    -webkit-touch-callout: text;
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    .title{
        padding: 0.5rem 1rem;
        font-size: 1.2rem
    }
    .operation{
        padding: 0 1rem 1rem 1rem;
        #uploadList{
            opacity: 0;
            position: absolute;
            left: 0;
            width: 116px;
            cursor: pointer;
        }
        .operationItem{
            display: flex;
            height: 1.6rem;
            line-height: 1.6rem;
            .ant-input{
                flex: 1;
            }
        }
        .operationRight{
            float: right;
        }
    }
    .table{
        padding: 0 1rem 0 1rem;
    }
    .hotLabel{
        display: inline-block;
        background-color: #f60;
        color: #fff;
        width: 18px;
        height: 18px;
        line-height: 18px;
        font-size: 0.6rem;
        text-align: center;
        border-radius: 4px;
        margin: 2px 5px;
    }
}