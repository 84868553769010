.policySignFrame {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .topHeadBox {
    flex-shrink: 0;
    height: 3rem;
    background: #fff;
    border-radius: 0.4rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .pageTitleBox {
      display: flex;
      align-items: center;

      .iconfont {
        font-size: 1.2rem;
        color: #1890ff;
        margin-right: 0.5rem;
      }

      .pageTitle {
        font-size: 0.9rem;
        line-height: 1rem;
        color: #7a7676;
      }
    }
  }

  .contentBox {
    display: flex;
    flex: 1;
    overflow: hidden;

    > div {
      height: 100%;
      background: #fff;
      border-radius: 0.4rem;
      padding: 1rem;
    }

    .leftConditionBox {
      flex: 1 1 20%;
      min-width: 14rem;
      max-width: 17rem;
      display: flex;
      flex-direction: column;

      .tabListBox {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.5rem;
        box-shadow: 0 0.05rem 0 0 #e8e8e8;

        .tabItem {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1 1 50%;
          padding-bottom: 0.5rem;
          line-height: 1rem;
          position: relative;
          color: #333;
          cursor: pointer;

          .iconfont {
            font-size: 0.9rem;
            margin-right: 0.2rem;
            color: #bdbdbd;

            &.icon-search {
              font-size: 1rem;
            }
          }

          .tabName {
            font-size: 0.65rem;
          }

          &.activeStatus {
            color: #1890ff;

            .iconfont {
              color: #1890ff;
            }

            &:after {
              content: "";
              position: absolute;
              width: 1.2rem;
              height: 0.1rem;
              background: #1890ff;
              border-radius: 0.05rem;
              bottom: 0;
              left: calc(50% + 0.5rem);
              transform: translateX(-50%);
            }
          }

          + .tabItem {
            &:before {
              content: "";
              position: absolute;
              width: 0.05rem;
              height: 0.6rem;
              background: #e8e8e8;
              left: 0;
              top: 0.2rem;
            }
          }
        }
      }

      .tabContentBox {
        flex: 1;
        overflow: hidden;

        > div {
          height: 100%;
          overflow: auto;
        }

        .nowAddContentBox {


          .sortItem:hover{
            background: #f6f6f6;
          }
          .sortItem {
            display: flex;
            align-items: center;
            border-radius: 0.2rem;
           
            padding: 0.3rem 0.5rem;
            line-height: 1rem;
            position: relative;
            cursor: pointer;
            

            .sortName {
              font-size: 0.8rem;
              color: #555555;
              margin-left: 10px;
              flex: 1;
            }

            .count {
              margin-left: 0.5rem;
              font-size: 0.8rem;
              color: #3D3D3D;
              float: right;
            }
            .anticon-folder-open{
              color: #60B4F6
            }

            &.activeStatus {
              background: #409EFF;

              .sortName, .count {
                color: #fff;
              }
              .anticon-folder-open{
                color: #fff;
              }

              // &:before {
              //   content: "";
              //   width: 0.1rem;
              //   height: 50%;
              //   border-radius: 0.1rem;
              //   position: absolute;
              //   left: 0.5rem;
              //   top: 25%;
              //   background: #fff;
              // }
            }

            +.sortItem {
              margin-top: 0.5rem;
            }
          }

          .labelNumBox{
            display: flex;
            line-height: 1.5rem;
            padding-left: 1.5rem;
            padding-right: 0.5rem;
            cursor: pointer;
            color: #3D3D3D;
            &.activeLabel{
              color: #409EFF;
            }
            .labelName{
              flex: 1;
              font-size: 0.65rem;
            }
            .labelCount{
              font-size: 0.65rem;
            }
          }
        }

        .historySearchBox {
          display: flex;
          flex-direction: column;
          overflow-x: hidden;
          overflow-y: auto;
          > div {
            .labelTitle {
              display: block;
              font-size: 0.65rem;
              margin-bottom: 0.5rem;
              line-height: 1rem;
              background: #f6f6f6;
              padding: 0.2rem 0.5rem;
              border-radius: 0.2rem;
            }

            .searchContentBox {
              // padding-left: 0.5rem;
              margin-top: 0.5rem;
            }
          }

          .dateSearchBox {
            flex-shrink: 0;
            margin-bottom: 1rem;
            display: flex;
            align-items: center;
            background: #f6f6f6;
            padding: 0.2rem 0.5rem;
            border-radius: 0.2rem;

            .labelTitle {
              margin-bottom: 0;
              background: none;
              padding: 0;
              margin-right: 0.5rem;
            }

            .searchContentBox {
              flex: 1;
              padding: 0;
              height: 1rem;
              display: flex;
              align-items: center;

              .ant-picker {
                background: none;
                border: none;
                box-shadow: none;

                .ant-picker-input {
                  input {
                    text-align: right;
                    line-height: 1rem;
                    padding: 0 0.3rem;
                  }
                }
              }
            }
          }

          .taxSearchBox {
            flex: 1;
            display: flex;
            flex-direction: column;
            // overflow: hidden;

            .searchContentBox {
              flex: 1;
              overflow: auto;
              margin-right: -0.2rem;
              padding-right: 0.2rem;

              .conditionItem {
                display: flex;
                align-items: center;
                cursor: pointer;

                .choiceBox {
                  flex-shrink: 0;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 0.8rem;
                  height: 0.8rem;
                  border-radius: 0.1rem;
                  border: 0.05rem solid #d9d9d9;
                  margin-right: 0.5rem;

                  .iconfont {
                    font-size: 0.6rem;
                    font-weight: bold;
                    color: #fff;
                    opacity: 0;
                  }
                }

                .conditionName {
                  font-size: 0.7rem;
                  line-height: 1rem;
                  color: #333;
                }

                &:hover {
                  .conditionName {
                    color: #1890ff;
                  }
                }

                &.selectedStatus {
                  .choiceBox {
                    background: #1890ff;
                    border-color: #1890ff;

                    .iconfont {
                      opacity: 1;
                    }
                  }
                }

                +.conditionItem {
                  margin-top: 0.5rem;
                }
              }

              .noneDataBox {
                height: 100%;
                flex-direction: column;
                justify-content: flex-start;
                padding-top: 2rem;

                .iconfont {
                  margin: 0 0 0.5rem 0;
                  font-size: 1.5rem;
                }

                > span {
                  font-size: 0.7rem;
                }
              }
            }
          }
        }
      }
    }

    .rightContentBox {
      flex: 1 1 75%;
      margin-left: 1rem;
      // padding-top: 0.75rem;
      overflow: hidden;

      .ant-spin-nested-loading {
        height: 100%;

        .ant-spin-container {
          height: 100%;

          .policyBox {
            height: 100%;
            position: relative;

            > div {
              height: 100%;
            }

            .uploadBtnBox {
              position: absolute;
              height: unset;
              right: 0;
              top: -0.3rem;
              z-index: 10;
            }
          }
        }
      }
    }
  }
}
