$InputHeight: 1.4rem;

.policyDetailSignModalFrame {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .topHeadBox {
    flex-shrink: 0;
    height: 3rem;
    background: #fff;
    border-radius: 0.4rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;

    .backBtn {
      line-height: 1.5rem;
      cursor: pointer;

      .iconfont {
        font-size: 1.5rem;
        color: #1890ff;
      }
    }

    .pageTitleBox {
      display: flex;
      align-items: center;

      .iconfont {
        font-size: 1.2rem;
        color: #1890ff;
        margin-right: 0.5rem;
      }

      .pageTitle {
        font-size: 0.9rem;
        line-height: 1rem;
        color: #7a7676;
      }
    }

    > i {
      width: 1.5rem;
    }
  }

  .contentBox {
    // display: flex;
    // flex: 1;
    // overflow: hidden;

    // > div {
    //   height: 100%;
    //   background: #fff;
    //   border-radius: 0.4rem;
    //   padding: 1rem;
    // }

    // .documentPreviewBox {
    //   flex: 1 1 70%;
    //   overflow-y: auto;
    //   overflow-x: hidden;
    //   user-select: none;
    //   cursor: default;

    //   .ant-spin-nested-loading {
    //     height: 100%;

    //     .ant-spin-container {
    //       height: 100%;

    //       .fileDocumentBox {
    //         height: 100%;
    //         padding: 1rem 0 0;
    //         color: #333;
    //         display: flex;
    //         flex-direction: column;
    //         align-items: center;
    //         position: relative;

    //         .fileDocumentTitle {
    //           display: block;
    //           font-size: 0.8rem;
    //           text-align: center;
    //           margin-bottom: 1rem;
    //           flex-shrink: 0;
    //           position: relative;
    //           z-index: 1;
    //         }

    //         .fileDocumentDesc {
    //           flex: 1;
    //           width: 100%;
    //           overflow: auto;
    //           margin-right: -0.5rem;
    //           padding: 0 0.5rem 0 0;
    //           position: relative;
    //           z-index: 1;

    //           > div {
    //             height: 100%;
    //             overflow: hidden;

    //             iframe {
    //               height: 100%!important;
    //               width: 100%!important;
    //             }
    //           }

    //           .noneDataBox {
    //             height: 100%;
    //           }
    //         }

    //         .openFileBtn {
    //           position: absolute;
    //           right: 0;
    //           bottom: 0;
    //           line-height: 0;
    //           cursor: pointer;
    //           z-index: 3;

    //           .iconfont {
    //             font-size: 0.8rem;
    //             color: #60b4f6;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }

    .signFormDataBox {
      // width: 30%;
      // flex-shrink: 0;
      // min-width: 20rem;
      // margin-left: 1rem;
      // display: flex;
      // flex-direction: column;
      // padding: 0;
      // overflow: hidden;
      // line-height: 1rem;

      // > div {
      //   flex-shrink: 0;
      // }

      .formTopInfoBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 2rem;
        padding: 0 1rem;
        box-shadow: 0 0.05rem 0 0 #f0f2f5;

        .leftInfoBox {
          display: flex;
          align-items: center;

          .infoItem {
            display: flex;
            align-items: center;

            .labelText {
              font-size: 0.6rem;
              color: #989696;
            }

            .valueText {
              font-size: 0.6rem;
              color: #333;
              margin-left: 0.2rem;
            }

            +.infoItem {
              margin-left: 0.5rem;
            }
          }
        }

        .rightOperateBox {
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .operateBtn {
            font-size: 0.6rem;
            color: #fff;
            padding: 0.1rem 0.5rem;
            cursor: pointer;
            background: #1890ff;
            border-radius: 0.2rem;

            &.removeBtn {
              background: #ff3a36;
            }

            +.operateBtn {
              margin-left: 0.5rem;
            }
          }
        }
      }

      .formContentBox {
        // flex: 1;
        // padding: 1rem;
        // overflow: hidden;

        .scrollBox {
          height: 100%;
          overflow: hidden auto;
          margin-right: -0.5rem;
          padding-right: 0.5rem;

          .formInfoItemBox {

            .infoTitleBox {
              display: flex;
              align-items: center;

              .iconfont {
                font-size: 0.85rem;
                font-weight: bold;
                margin-right: 0.5rem;

                &.icon-fenlei {
                  font-size: 0.9rem;
                  top: -0.05rem;
                }
              }

              .infoTitle {
                font-size: 0.7rem;
                font-weight: bold;
                color: #333;
              }
            }

            .formItemListBox {
              margin-top: 1rem;
              padding-left: 1rem;

              .formItem {
                display: flex;
                align-items: flex-start;
                position: relative;

                .labelText {
                  flex-shrink: 0;
                  font-size: 0.6rem;
                  color: #979797;
                  width: 2.5rem;
                  height: $InputHeight;
                  line-height: $InputHeight;
                  text-align-last: justify;
                  margin-right: 0.8rem;
                  position: relative;

                  &:after {
                    content: ":";
                    position: absolute;
                    right: -0.2rem;
                  }
                }

                .formItemContent {
                  flex: 1;
                  min-height: $InputHeight;

                  .ant-input {
                    display: block;
                    width: 100%;
                    height: $InputHeight;
                    padding: 0 0.5rem;
                    font-size: 0.6rem;
                    color: #333;
                    line-height: 1rem;
                    border-radius: 0.2rem;
                  }

                  .ant-picker {
                    display: inline-flex;
                    align-items: center;
                    height: $InputHeight;
                    padding: 0 0.5rem;
                    border-radius: 0.2rem;

                    .ant-picker-input {
                      input {
                        font-size: 0.6rem;
                        color: #333;
                      }
                    }
                  }

                  .ant-select {
                    width: 100%;

                    .ant-select-selector {
                      min-height: $InputHeight;
                      border-radius: 0.2rem;
                      box-shadow: none;
                      padding-left: 0.5rem;

                      .ant-select-selection-overflow {
                        z-index: 1;
                        .ant-select-selection-overflow-item {
                          .ant-select-selection-search {
                            margin-inline-start: 0;
                          }
                        }
                      }

                      .ant-select-selection-placeholder {
                        font-size: 0.6rem;
                        left: 0.5rem;
                      }

                      .ant-select-selection-item {
                        font-size: 0.6rem;
                        color: #333;
                      }
                    }
                  }

                  .ant-input-textarea {
                    font-size: 0.6rem;

                    .ant-input {
                      font-size: 0.6rem;
                      color: #333;
                      resize: none;
                      height: 5rem;
                      padding: 0.2rem 0.5rem;
                      line-height: 1rem;
                    }
                  }

                  .policyLabelListBox {
                    display: flex;
                    flex-flow: row wrap;
                    overflow: hidden;
                    margin: -0.25rem;
                    line-height: 1rem;

                    .labelItem {
                      display: flex;
                      align-items: center;
                      margin: 0.25rem;
                      max-width: calc(100% - 1.25rem);
                      overflow: hidden;
                      padding: 0.2rem 0.5rem;
                      background: #ededed;
                      border-radius: 0.2rem;

                      .labelItemText {
                        font-size: 0.6rem;
                        color: #7a7676;
                      }

                      .deleteBtn {
                        flex-shrink: 0;
                        margin-left: 0.5rem;
                        cursor: pointer;
                        line-height: 0;
                        transform: scale(0.8);

                        .iconfont {
                          font-size: 0.6rem;
                          color: #b7b6b6;
                        }
                      }

                      &.addLabelBtn {
                        border-radius: 50%;
                        cursor: pointer;
                        width: $InputHeight;
                        height: $InputHeight;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0;

                        .iconfont {
                          font-size: 1rem;
                          color: #1a9df9;
                        }
                      }
                    }
                  }

                  .selectedAreaOrDepartmentBox {
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    background: #fff;
                    border-radius: 0.2rem;
                    cursor: pointer;
                    align-items: flex-start;
                    overflow: hidden;
                    flex: 0 1 auto;

                    .operateBtnBox {
                      display: flex;
                      align-items: center;

                      .iconBtn {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        line-height: 1.3rem;
                        height: 1.3rem;
                        flex-shrink: 0;
                        z-index: 1;
                        font-size: 0.6rem;
                        background: #1890ff;
                        color: #fff;
                        border-radius: 0.2rem;
                        padding: 0 0.3rem 0 0.2rem;

                        .iconfont {
                          font-size: 0.8rem;
                          margin-right: 0.1rem;
                        }
                      }

                      .selectedOperateBox {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        flex-shrink: 0;
                        margin-left: 0.5rem;

                        .listItem {
                          background: #ff4d4f;
                          border-radius: 0.2rem;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          cursor: pointer;
                          margin: 0;
                          font-size: 0.6rem;
                          padding: 0 0.3rem;
                          line-height: 1.3rem;

                          .itemText  {
                            background: none;
                            color: #fff;
                          }

                          .clickBtn {
                            position: relative;
                            top: unset;
                            right: unset;
                            line-height: 0;
                            display: block;

                            .iconfont {
                              font-size: 0.65rem;
                              margin-right: 0.2rem;
                              color: #fff;
                              zoom: 1;
                            }
                          }
                        }
                      }
                    }

                    .areaOrDepartmentContentBox {
                      position: relative;
                      height: 100%;
                      padding: 0.25rem 0;
                      margin-bottom: -0.5rem;

                      .areaOrDepartmentListBox {
                        flex: 1;
                        //max-height: 7rem;
                        //overflow: auto;
                        display: flex;
                        flex-direction: column;
                        flex-flow: row wrap;
                        align-items: center;
                        align-content: flex-start;
                        margin: 0 -0.25rem 0;

                        .listItem {
                          display: inline-flex;
                          align-items: center;
                          background: #ebebeb;
                          cursor: default;
                          max-width: 100%;
                          position: relative;
                          margin: 0.25rem 0.25rem;
                          line-height: 0;
                          padding: 0.1rem 0.3rem;
                          border-radius: 0.2rem;
                          overflow: hidden;

                          .itemText {
                            display: inline-block;
                            font-size: 0.6rem;
                            color: #333;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            line-height: 1rem;
                          }

                          .clickBtn {
                            display: block;
                            line-height: 0;
                            cursor: pointer;
                            margin-left: 0.3rem;

                            .iconfont {
                              font-size: 0.8rem;
                              color: #333;
                              zoom: 0.7;
                            }
                          }
                        }
                      }
                    }
                  }
                }

                .tipText {
                  position: absolute;
                  left: 3.5rem;
                  top: 100%;
                  font-size: 0.6rem;
                  color: #ff3a36;
                }

                +.formItem {
                  margin-top: 1.2rem;
                }
              }
            }

            .isRequired:before {
              content: "*";
              position: absolute;
              left: -0.4rem;
              top: 0.8rem;
              color: #ff3a36;
              font-size: 0.8rem;
              line-height: 0;
            }

            + .formInfoItemBox {
              box-shadow: 0 -0.05rem 0 0 #f0f2f5;
              margin-top: 1.5rem;
              padding-top: 1rem;
            }
          }
        }
      }

      .fromBottomOperateBox {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        line-height: 1rem;
        height: 2.5rem;
        padding: 0 1rem;
        box-shadow: 0 -0.05rem 0 0 #f0f2f5;

        .operateBtn {
          font-size: 0.6rem;
          color: #fff;
          padding: 0.2rem 1.5rem;
          cursor: pointer;
          background: #1890ff;
          border-radius: 0.2rem;

          &.cancelBtn {
            background: #e8e8e8;
            color: #333;
          }

          +.operateBtn {
            margin-left: 1rem;
          }
        }
      }
    }
  }
}

.createPolicyLabelBox {
  display: flex;
  align-items: center;
  padding: 1.5rem 0 0;

  .labelName {
    flex-shrink: 0;
    margin-right: 0.5rem;
  }

  .ant-input {
    border-radius: 0.2rem;
  }
}
.governmentPopoverBox{
  .sortTypeListBox {
    flex-shrink: 0;
    display: flex;
    align-items: center;

    .sortItem {
      font-size: 0.7rem;
      color: #333;
      cursor: pointer;
      padding: 0.5rem 0.5rem;
      line-height: 1rem;
      position: relative;

      &.activeStatus {
        &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 0.1rem;
          background-color: #1A9DF9;
          bottom: 0;
          left: 0;
        }
      }

      +.sortItem {
        margin-left: 1rem;
      }
    }
  }

  .areaListBox {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: #F0F5F8;
    padding: 0.5rem;
    border-radius: 0.4rem;

    .regionsAllArea {
      flex-shrink: 0;
      font-size: 0.7rem;
      color: #333;
      font-weight: 600;
      line-height: 1rem;
      cursor: pointer;
      margin-bottom: 0.25rem;
    }

    .regionsAllListBox {
      flex: 1;
      overflow: auto;

      .regionsLists {
        display: flex;
        line-height: 1rem;

        .regionsArea {
          flex-shrink: 0;
          font-size: 0.7rem;
          line-height: 1.2rem;
          color: #333;
          font-weight: 600;
          cursor: pointer;
          margin-right: 0.5rem;
        }

        .regionsBox {
          flex: 1;
          overflow: auto;
          display: flex;
          flex-flow: row wrap;
          align-content: flex-start;
          align-items: center;

          .regionsItem {
            display: block;
            margin: 0.1rem 0.25rem;
            cursor: pointer;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 0.6rem;
            line-height: 1rem;
            color: #494848;
          }
        }
      }
    }


  }
}
.regionsPopoverBox{
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .sortTypeListBox {
    flex-shrink: 0;
    display: flex;
    align-items: center;

    .sortItem {
      font-size: 0.7rem;
      color: #333;
      cursor: pointer;
      padding: 0.5rem 0.5rem;
      line-height: 1rem;
      position: relative;

      &.activeStatus {
        &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 0.1rem;
          background-color: #1A9DF9;
          bottom: 0;
          left: 0;
        }
      }

      +.sortItem {
        margin-left: 1rem;
      }
    }
  }

  .areaListBox {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: #F0F5F8;
    padding: 0.5rem;
    border-radius: 0.4rem;

    .regionsAllArea {
      flex-shrink: 0;
      font-size: 0.7rem;
      color: #333;
      font-weight: 600;
      line-height: 1rem;
      cursor: pointer;
      margin-bottom: 0.25rem;
    }

    .regionsAllListBox {
      flex: 1;
      overflow: auto;

      .regionsList {
        display: flex;
        line-height: 1rem;

        .regionsArea {
          flex-shrink: 0;
          font-size: 0.7rem;
          line-height: 1.2rem;
          color: #333;
          font-weight: 600;
          cursor: pointer;
          margin-right: 0.5rem;
        }

        .regionsBox {
          flex: 1;
          overflow: auto;
          display: flex;
          flex-flow: row wrap;
          align-content: flex-start;
          align-items: center;

          .regionsItem {
            display: block;
            margin: 0.1rem 0.25rem;
            cursor: pointer;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 0.6rem;
            line-height: 1rem;
            color: #494848;
          }
        }
      }
    }


  }

  .footerOperateBox {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 2rem;
    margin-top: 0.3rem;

    .operateBtn {
      flex: 1 1 50%;
      font-size: 0.7rem;
      color: #575757;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #e0e0e0;
      border-radius: 0.2rem;
      height: 1.6rem;

      &.confirmBtn {
        color: #fff;
        background: #1A9DF9;
      }

      +.operateBtn {
        margin-left: 1rem;
      }
    }
  }

  .selectRegion {
    color: #1890ff!important;
  }

  &.departmentPopoverBox {
    padding-top: 0.3rem;
    .firstTabs{
      color: #4E5969;
      font-size: 0.7rem;
      height: 1.6rem;
      line-height: 1.6rem;
      margin-bottom: 0.5rem;
      div{
          float: left;
          margin-right: 0.5rem;
          border-radius: 0.8rem;
          padding: 0 0.8rem;
          cursor: pointer;
      }
      .select{
          background: #F2F3F5;
          color: #165DFF;
      }
    }
    .areaListBox {
      .regionsAllListBox {
        .regionsList {
          flex-direction: column;

          .regionsBox {
            padding-left: 0.5rem;
          }

          +.regionsList {
            margin-top: 0.5rem;
          }
        }
      }
    }
  }
}

.areaOrDepartmentTooltipBox {
  max-width: unset !important;
  .ant-tooltip-content {
    margin-right: -0.5rem;

    .ant-tooltip-arrow {
      .ant-tooltip-arrow-content {
        --antd-arrow-background-color: #fff;
      }
    }

    .ant-tooltip-inner {
      background: #fff;
      overflow: hidden;
      border-radius: 0.4rem;
      box-shadow: 0 0 0.7rem 0 rgb(176 176 176);
      padding: 0;

      .areaOrDepartmentContentBox {
        width: 20rem;
        // height: 15.5rem;
        padding: 0.2rem 0.5rem;
        overflow: auto;
      }
    }
  }
}
