.knowledgeListFrame{
    flex: 1;
    overflow: auto;
    margin-bottom: 10px;
    margin-top: 1rem;
    .ant-list-item{
        padding: 10px 0;
    }
    .ant-list-vertical .ant-list-item-meta{
        margin-bottom: 0;
    }

    .jumpPointer{
        max-width: 190px;
    }
    .jumpPointer,.listTitle{
        color: #5FA8F2;
        cursor: pointer;
    }

    .ant-list-vertical .ant-list-item-meta-title{
        margin-bottom: 0
    }
    .ant-list-lg .ant-list-item{
        padding-left: 0
    }
    .listBox{
        overflow: auto;
        .listItem{
            cursor: pointer;
            background: #F0F5F8;
            border-radius: 8px;
            padding: 0.5rem;
            margin-bottom: 0.5rem;
        }
        .listItem:last-child{
            margin-bottom: 0;
        }
        .listItemHeader{
            font-size: 0.6rem;
            color: #666666;
            display: flex;
            .anticon {
                margin-right: 0.5rem;
            }

            .iconfont {
                margin-right: 0.5rem;
                top: 0.05rem;
            }
            .icon-waixingren{
                margin-right: 0;
                top: 0;
            }

            .listTitle{
                flex: 1;
                font-size: 0.8rem;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            .releaseTime{
                line-height: 1rem;
            }
            .openZipIcon{
                cursor: pointer;
                margin-top: 5px;
            }

            .AiBtnBox {
                flex-shrink: 0;
                margin-right: 0.5rem;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 4.2rem;
                height: 1rem;
                border-radius: 0.5rem;
                opacity: 0.7;
                // overflow: hidden;

                .AiBtn {
                    flex-shrink: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 1.5rem;
                    height: 100%;
                    color: #fff;
                    position: relative;
                    border-radius: 0.5rem 0 0 0.5rem;
                    .icon-waixingren {
                        font-size: 0.7rem;
                    }

                    .icon-jiahao {
                        font-size: 1rem;
                        zoom: 0.3;
                        position: absolute!important;
                        bottom: 0.4rem;
                        left: 3.2rem;
                    }
                }
                .shoppingCartBtnAvailable{
                    background: #1890ff;
                    border: 0.05rem solid #1890ff;
                }
                .shoppingCartBtnNotAvailable{
                    background: #a0a0a0;
                    border: 0.05rem solid #a0a0a0;
                    cursor: no-drop;
                }


                .readBtnAvailable{
                    border: 0.05rem solid #1890ff;
                }
                .readBtnNotAvailable{
                    border: 0.05rem solid #409EFF;
                    cursor: no-drop;
                }
                .AiReadBtn {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 0.5rem;
                    border-radius: 0 0.5rem 0.5rem 0;
                    line-height: 18px;
                    color: #3D3D3D;
                }
            }
            .radioCheckbox{
                .ant-checkbox{
                    border-radius: 50%;
                    overflow: hidden;
                    .ant-checkbox-input{
                        border-radius: 50%;
                        overflow: hidden;
                    }
                }
                .ant-checkbox-inner{
                    border-radius: 50%;
                    overflow: hidden;
                }
            }
        }
        .childrenlistBox{
            background: #fff;
        }
        .childrenlistBoxH{
            background: #F0F5F8;
        }
        .childrenlistBox,.childrenlistBoxH{
            border-radius: 8px;
            padding: 0.8rem 1.5rem;
            margin-top: 0.7rem;
            .childrenlistItemHeader{
                font-size: 0.6rem;
                color: #666666;
                display: flex;
                margin-bottom: 0.5rem;
                .anticon {
                    margin-right: 0.5rem;
                }
                .iconfont {
                    margin-right: 0.5rem;
                    top: 0.05rem;
                }
                .listTitle{
                    flex: 1;
                    font-size: 0.8rem;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
            .childrenlistItemHeader:last-child{
                margin-bottom: 0
            }
        }
        
        .listItemContent{
            font-size: 0.7rem;
            color: #666666;
            margin-bottom: 0.7rem;
            margin-top: 0.5rem;
            padding-left: 1.5rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            white-space: pre-wrap;
            word-wrap: break-word;
            word-break: break-all;
        }
        .listItemFooter{
            font-size: 0.6rem;
            color: #999999;
            padding-left: 1.5rem;
            display: flex;
            margin-top: 0.5rem;
            .listItemFooterItem{
                flex: 1;
                >span{
                    display: inline-block;
                    margin-right: 1rem;
                }
            }
            .listItemFooterOperation{
                .iconfont {
                    float: left;
                    margin-right: 5px;
                }
            }
        }
    }
    .pagination{
        text-align: right;
        margin-top: 10px;
        margin-right: 10px;
    }
    .ant-list-vertical .ant-list-item-action{
        margin-top: 0.2rem;
    }
    .authorBox,.jumpPointer,.subclassification{
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        float: right;
    }
    .subclassification{
        max-width: 200px;
    }
}
