.PCLleftBox {
  position: relative;
  top: 0;
  left: 0;
  width: 14.1rem;
  height: 100%;
  background: #fff;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  // align-items: center;
  // padding-bottom: 0.5rem;
  // overflow-y: auto;
  .topList{
    flex: 1;
    overflow: auto;
  }
  .topBox{
    flex-shrink: 0;
    max-height: 16rem;
    width: 13.1rem;
    background: #60B4F6;
    box-shadow: 0 3px 14px 1px rgba(96,180,246,0.5);
    border-radius: 8px 8px 8px 8px;
    // position: relative;
    // top: -10px;
    margin: 0.5rem auto 0 auto;
    .user {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-around;
      padding: 1rem 0;
      // margin-top: 1.5rem;
      .ava {
        width: 5.3rem;
        height: 5.3rem;
        margin-bottom: 1rem;
        border-radius: 50%;
        overflow: hidden;
        border: 3px solid #fff;
        background: #60B4F6;
        .avatar-uploader{
          display: inline-block;
          width: 4.7rem;
          height: 4.7rem;
          border-radius: 50%;
          overflow: hidden;
          margin: 3px 0 0 3px;
          .ant-upload.ant-upload-select-picture-card{
            width: 4.7rem;
            height: 4.7rem;
          }
        }
      }

      .info {
        text-align: center;
        margin-bottom: 1rem;
        color: #fff;
        div {
          font-size: 0.8rem;
        }
        span {
          font-size: 0.7rem;
        }

        .userDescription {
          font-size: 0.6rem;
          margin-top: 0.25rem;
          width: 100%;
          overflow: hidden;
          white-space: pre-wrap;
          word-break: break-all;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
        }
      }


      .uploadBtn {
        button {
          width: 11.1rem;
          height: 2.1rem;
          background: #FFFFFF;
          box-shadow: 0px 3px 6px 1px rgba(25,121,196,0.48);
          border-radius: 8px;
          border: 0;
        }
      }

    }
  }

  .menuBox{
    flex: 1;
    width: 100%;
    // overflow-y: auto;
    border-radius: 0.4rem;
    margin-top: 1rem;

    .menuList{
      .moreIcon{
        position: relative;
        background-color: #E9EDF5;
        float: right;
        width: 40px;
        height: 26px;
        line-height: 26px;
        text-align: center;
        color: #000;
        margin-top: 7px;
        border-radius: 4px;
        cursor: pointer;
        z-index: 10;

        .anticon-ellipsis{
          font-size: 20px;
        }
      }

      .rightOperateBox {
        float: right;
        display: flex;
        justify-content: flex-end;
        line-height: 1rem;
        margin-top: 0.35rem;

        .expandBtn {
          margin-left: 0.5rem;
          cursor: pointer;
          width: 1rem;
          height: 1rem;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          right: -0.25rem;

          .ant-menu-submenu-arrow {
            position: relative;
            transform: unset;
            right: 0;
            top: 0.05rem;
          }

          &.expandStatus {
            .ant-menu-submenu-arrow {
              &:before {
                transform: rotate(45deg) translateX(2.5px);
              }
              &:after {
                transform: rotate(-45deg) translateX(-2.5px);
              }
            }
          }
        }

        .moreIcon {
          display: none;
          margin-top: 0;
        }
      }

      .ant-menu-submenu {
        position: relative;

        .ant-menu-submenu-title {
          .ant-menu-title-content {
            margin-left: 0;
          }
        }

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 0.15rem;
          height: 2rem;
          opacity: 0;
          background: #3DADEE;
          transition: opacity 0.15s;
        }

        &.ant-menu-submenu-selected {
          &:after {
            opacity: 1;
          }
        }

        &.ant-menu-submenu-open {
          &:after {
            opacity: 0!important;
          }
        }
      }

      .ant-menu-item {
        &:hover {
          .rightOperateBox {
            .moreIcon {
              display: block;
            }
          }
        }
      }
    }
    .spaceTreeBox{
      margin: 0.5rem 0 0;
      max-height: 40vh;
      overflow-y: auto;
      transition: max-height 0.3s linear, margin 0s linear 0s;

      &.foldStatus {
        margin: 0;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s linear, margin 0s linear 0.3s;
      }

      .ant-tree-treenode{
        width: 100%;
        .ant-tree-node-content-wrapper{
          width: 100%;
          height: 26px;
          line-height: 26px;
        }
      }

      //.ant-tree-list {
      //  .ant-tree-treenode {
      //    .ant-tree-checkbox {
      //      .ant-tree-checkbox-inner {
      //        &:before {
      //          content: "";
      //          width: 7rem;
      //          height: 1.3rem;
      //          position: absolute;
      //          left: 0;
      //          top: -0.25rem;
      //          z-index: 10;
      //        }
      //      }
      //    }
      //  }
      //}

      .moreIcon{
        margin-top: 0;
      }
    }
    .ant-menu-submenu{
      .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
        background: #e6f7ff;
      }
    }

    .ant-menu-inline.ant-menu-root .ant-menu-item{
      margin: 0 0.5rem;
      overflow: initial;
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background: #F0F5F8;
      border-radius: 8px;
    }
    .ant-menu-inline .ant-menu-item::after{
      left: -0.5rem;
      right: auto;
      border-color: #3DADEE;
    }
    .ant-menu-sub.ant-menu-inline{
      background: #F0F5F8;
      border-radius: 8px;

      .ant-menu-item{
        padding-left: 54px !important;
      }
    }
    .ant-menu-submenu-open{
      background: #F0F5F8;
      border-radius: 8px;
    }
    .ant-menu-item-selected a, .ant-menu-item-selected a:hover{
      color: #0099FF;
    }
    .ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title{
      width: auto;
    }
    .ant-menu-inline .ant-menu-submenu{
      margin: 0 0.5rem;
      overflow: initial;
    }
    .ant-menu-item a{
      color: #333333;
    }
    .ant-menu-submenu-expand-icon, .ant-menu-submenu-arrow {
      color: #537791;
    }
    .icon-fangzi{
      color: #2A8EDD;
    }
    .icon-leafsxian{
      color: #259317;
    }
    .icon-shu1{
      color: #D96704;
    }
    .icon-shoucang2{
      color: #E54646;
    }
    .icon-bijiben{
      color: #1042B2;
    }
    .icon-fenxiang3{
      color: #A62DD3;
    }
    .icon-shangchuanyunkongjian{
      color: #E829E5;
    }
    .icon-huiyuanzuanshi-xianxingtubiao-01{
      color: #D9238B;
    }
  }

  .spaceInfoBox {
    flex-shrink: 0;
    margin-top: 0.5rem;
    width: 100%;
    line-height: 1rem;
    user-select: none;
    padding: 0 1rem 0.5rem 1rem;

    .infoUsedValueBox, .usedProcessBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .infoUsedValueBox {
      .usedValue {
        font-size: 0.6rem;
        color: #3D3D3D;
      }

      .manageBtn {
        cursor: pointer;
        font-size: 0.6rem;
        color: #409EFF;
      }
    }

    .usedProcessBox {
      height: 1rem;

      .processBox {
        position: relative;
        flex: 1;
        height: 0.3rem;
        border-radius: 0.3rem;
        background: #E7E7E7;
        overflow: hidden;

        .processBar {
          position: absolute;
          left: 0;
          top: 0;
          max-width: 100%;
          height: 100%;
          background: #0256FF;
          border-radius: 0.3rem;
        }
      }

      .iconfont {
        flex-shrink: 0;
        font-size: 0.8rem;
        margin-left: 0.8rem;
        color: #0256FF;
      }
    }

    .usedTipText {
      display: block;
      font-size: 0.6rem;
      color: #0256FF;
      line-height: 0.6rem;
    }

    &.waring {
      .usedProcessBox {
        .processBox {
          .processBar {
            background: #E37318;
          }
        }

        .iconfont {
          color: #E37318;
        }
      }

      .usedTipText {
        font-size: 0.6rem;
        color: #E37318;
      }
    }

    &.fail {
      .usedProcessBox {
        .processBox {
          .processBar {
            background: #ff3a36;
          }
        }

        .iconfont {
          color: #ff3a36;
        }
      }

      .usedTipText {
        font-size: 0.6rem;
        color: #ff3a36;
      }
    }
  }
}

.personalCenterTooltipBox {
  .ant-tooltip-arrow {
    .ant-tooltip-arrow-content {
      &:before {
        background: #fff;
      }
    }
  }

  .ant-tooltip-inner {
    padding: 0;
    background: none;
    position: relative;
    top: -0.5rem;
    overflow: hidden;

    .modal {
      display: flex;
      flex-direction: column;
      background: #fff;
      box-shadow: 0 0 0.6rem 0 #ccc;
      border-radius: 0.4rem;
      padding: 1rem;
      overflow: hidden;

      .modalBtn {
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #073fa6;

        .iconfont {
          font-size: 1.3rem;
          margin-right: 0.6rem;
        }

        > span {
          font-size: 0.7rem;
        }

        &.disabledBtn {
          cursor: not-allowed;
          opacity: 0.6;
        }

        +.modalBtn {
          margin-top: 1rem;
        }
      }
      .disabledBtn{
        color: #666;
        cursor: not-allowed;
      }
    }
  }


}

.operateMenu::-webkit-scrollbar {
  display: none;
}

.myUpload {
  position: fixed;
  z-index: 999;
  background: rgba(0,0,0,0.3);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .uploadContentBox {
    width: 60%;
    max-width: 32rem;
    min-width: 26rem;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 0.4rem;
    overflow: hidden;

    > div {
      padding: 1rem;
    }

    .headBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 1rem;
      box-shadow: 0 0.05rem 0 0 #e5e5e5;

      .titleText {
        font-size: 0.8rem;
        color: #7a7676;
        cursor: default;
      }

      .closeBtn {
        line-height: 0;
        margin-left: 1rem;
        cursor: pointer;

        .iconfont {
          font-size: 0.7rem;
          color: #8f8f8f;
        }
      }
    }

    .selectFileListBox {
      display: flex;
      align-items: center;
      height: 32vh;
      overflow: hidden;

      > div {
        height: 100%;
        overflow: hidden;
      }

      .uploadBtnBox {
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 3.5rem 0 2.5rem;
        position: relative;

        .uploadBtn {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 3rem;
          height: 3rem;
          border-radius: 0.4rem;
          border: 0.05rem dashed #cbc8c8;
          cursor: pointer;
          position: relative;

          .iconfont {
            font-size: 2rem;
            color: #cbc8c8;
          }

          .tipText {
            position: absolute;
            width: 10rem;
            top: 120%;
            left: -3rem;
            white-space: pre-wrap;
            word-break: break-all;
            text-align: center;
            font-size: 0.6rem;
            color: #afaeae;
            line-height: 0.9rem;
            padding-right: 1rem;
            transform: scale(0.9);
          }
        }

        #fileInput {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          opacity: 0;
          cursor: pointer;
        }
      }


      .fileListBox {
        flex: 1;
        box-shadow: -0.05rem 0 0 0 #e5e5e5;
        margin-right: -0.5rem;
        padding-right: 0.7rem;
        padding-left: 1rem;
        overflow: auto;

        .fileItem {
          display: flex;
          align-items: center;
          line-height: 1rem;

          .fileName {
            flex: 1;
            font-size: 0.7rem;
          }

          .deleteBtn {
            flex-shrink: 0;
            margin-left: 1rem;
            cursor: pointer;
            line-height: 0;

            &:hover, &:active {
              .iconfont {
                color: #ff4d4f;
              }
            }
          }

          +.fileItem {
            margin-top: 0.5rem;
          }
        }
      }

      .noneDataBox {
        height: 100%;
        flex-direction: column;

        .iconfont {
          margin: 0;
        }

        span {
          font-size: 0.7rem;
          margin-top: 0.5rem;
        }
      }
    }

    .footBox {
      box-shadow: 0 -0.05rem 0 0 #e5e5e5;

      .uploadBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2rem;
        font-size: 0.7rem;
        background: #1A9DF9;
        color: #fff;
        border-radius: 0.4rem;
      }
    }
  }
}

.spaceUsedStatusModalBox {
  .spaceUsedStatusBox {
    line-height: 1rem;

    .spaceUsedHeadBox {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .title {
        font-size: 0.8rem;
        font-weight: bolder;
        color: #1D2129;
      }

      .extendBtn {
        opacity: 0.5;
        margin-left: 2rem;
        font-size: 0.7rem;
        color: #409EFF;
        cursor: pointer;
      }
    }

    .spaceInfoList {
      margin-top: 2.5rem;
      padding: 0 1rem;

      .spaceItemBox {
        .infoBox {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .labelText {
            font-size: 0.6rem;
            font-weight: bolder;
            color: #3D3D3D;
          }

          .valueText {
            font-size: 0.6rem;
            color: #3D3D3D;
            margin-left: 0.5rem;
          }
        }

        .usedProcessBox {
          height: 1rem;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .processBox {
            position: relative;
            flex: 1;
            height: 0.4rem;
            border-radius: 0.4rem;
            background: #E7E7E7;


            .processBar {
              position: absolute;
              left: 0;
              top: 0;
              max-width: 100%;
              height: 100%;
              background: #0256FF;
              border-radius: 0.4rem;
            }

            .ant-tooltip {
              max-width: unset;

              .toolTipContentBox {
                display: flex;
                align-items: center;

                .iconfont {
                  color: #E37318;
                  margin-right: 0.3rem;
                }

                .tipText {
                  font-size: 0.7rem;
                  color: #1D2129;
                }

                .releaseBtn {
                  font-size: 0.7rem;
                  color: #409EFF;
                  margin-left: 0.5rem;
                  cursor: pointer;
                }
              }
            }
          }

          &.waring {
            .processBox {
              .processBar {
                background: #E37318;
              }

              .toolTipContentBox {
                .iconfont {
                  color: #E37318;
                  margin-right: 0.3rem;
                }
              }
            }
          }

          &.fail {
            .processBox {
              .processBar {
                background: #ff3a36;
              }

              .toolTipContentBox {
                .iconfont {
                  color: #ff3a36;
                  margin-right: 0.3rem;
                }
              }
            }
          }
        }

        +.spaceItemBox {
          margin-top: 3.2rem;
        }
      }
    }
  }
}

.spaceNotEnoughModalBox {
  .spaceNotEnoughBox {
    line-height: 1rem;

    .usedProcessBox {
      width: 3.5rem;
      height: 3.5rem;
      margin: 0 auto 0.7rem;

      .ant-progress-text {
        color: #0256FF;
      }

      &.waring {
        .ant-progress-text {
          color: #E37318;
        }
      }

      &.fail {
        .ant-progress-text {
          color: #ff3a36;
        }
      }
    }

    .tipTextOne {
      display: block;
      font-size: 0.7rem;
      font-weight: bold;
      color: #1D2129;
      text-align: center;
    }

    .tipTextTwo {
      display: block;
      font-size: 0.7rem;
      color: #4E5969;
      text-align: center;
    }

    .btnBox {
      margin-top: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .operateBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4.4rem;
        height: 1.6rem;
        border-radius: 0.1rem;
        cursor: pointer;
        background: #F2F3F5;
        font-size: 0.7rem;
        color: #1D2129;

        &.releaseBtn {
          background: #165DFF;
          color: #fff;
        }

        +.operateBtn {
          margin-left: 1rem;
        }
      }
    }

    .bottomTipBox {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0.5rem;
      font-size: 0.5rem;
      color: #3D3D3D;
      line-height: 0.7rem;

      .ruleBtn {
        margin-left: 0.2rem;
        cursor: pointer;
        border-bottom: 0.05rem solid #3D3D3D;
      }
    }
  }
}

.shareKnowledgeModalBox {
  .shareKnowledgeBox {
    line-height: 1rem;

    .title {
      display: block;
      font-size: 0.8rem;
      font-weight: bold;
      color: #1D2129;
    }

    .infoBox {
      margin: 1rem 0;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .infoDesc {
        font-size: 0.7rem;
        color: #1D2129;
      }

      .infoOperateBox {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .selectedNum {
          font-size: 0.6rem;
          color: #555;
        }

        .cancelSelectBtn {
          font-size: 0.6rem;
          color: #409EFF;
          cursor: pointer;
          margin-left: 1rem;
        }

        .shareBtn {
          margin-left: 1.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 5rem;
          height: 1.5rem;
          border: 0.05rem solid #BFBFBF;
          border-radius: 0.5rem;
          cursor: pointer;
          font-size: 0.8rem;
          color: #409EFF;
        }

        &.disabledStatus {
          .cancelSelectBtn, .shareBtn {
            cursor: not-allowed;
            opacity: 0.7;
          }
        }
      }
    }

    .tableBox {
      height: 50vh;

      .operateBtnBox {
        .goShareBth {
          display: flex;
          padding: 0.2rem 0.6rem;
          line-height: 0.9rem;
          font-size: 0.7rem;
          color: #fff;
          background: #409EFF;
          border-radius: 0.2rem;
          cursor: pointer;
        }
      }

      .shareStatusBox {
        .iconfont {
          top: -0.1rem;
          margin-right: 0.1rem;

          &.icon-dui {
            font-size: 0.7rem;
          }
        }
      }
    }
  }
}

.personCenterAddModalBox {

  .ant-modal {
    padding: 0 !important;
  }
}

.uploadFileModalBox {
  .ant-modal {
    box-shadow: none!important;
    .ant-modal-content {
      background: none!important;
      .ant-modal-body {
      }
    }
  }
}
